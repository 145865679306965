@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import '@fontsource/fira-sans';

body {
  margin: 0;
  background-color: theme('colors.backgroundPrimary');
  color: theme('colors.textPrimary');
  font-family: 'Fira Sans', sans-serif !important;
}

button,
a,
span,
h1,
h2,
h4,
h4,
h5,
h6,
p {
  font-family: 'Fira Sans', sans-serif !important;
}

.orangeBar {
  white-space: nowrap;
  font-weight: 500;
  padding: 0 0 0 15px;
  margin: 0 0 15px 0;
  font-size: 35px;
  position: relative;
  color: #fff;
}

.noBar {
  white-space: nowrap;
  font-weight: 500;
  padding: 0 0 0 15px;
  margin: 0 0 15px 0;
  font-size: 35px;
  position: relative;
  color: #fff;
  line-height: 19px;
}

.orangeBar::after {
  content: " ";
  width: 7px;
  height: 32px;
  position: absolute;
  background: #F55937;
  left: 0;
  top: 8px;
}

button {
  text-transform: none !important;
}

.ContainerHeader {
  padding-right: 0 !important;
}

.AppBarHeader {
  box-shadow: none !important;
}

.MuiButtonBase-root {
  text-decoration: none !important;
  font-weight: 400 !important;
  white-space: nowrap !important;
  padding: 10px 20px 0px 20px !important;
  margin: 0 10px 0 0 !important;
  font-size: 17px !important;
}

.MuiList-root {
  background-color: theme('colors.backgroundSecondary');
}

.ContactBox {
  position: relative !important;
  top: -5px !important;
}

.EmailIcon {
  width: 1.7rem !important;
}
/*FIRST SECTION*/

.firstSection {
  background-image: url('./assets/home/bg-01.png');
  background-repeat: no-repeat;
  background-size: cover;

}

.hostingtSection {
  background-image: url('./assets/home/bg-blur.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.bigTxt {
  font-size: 50px;
  line-height: 60px;
  text-transform: uppercase;
  font-weight: bolder;
}

.bigTxt .primary-bg {
  background-color: #F55937;
  color: #fff;
  display: inline-block;
  font-weight: bolder;
  padding: 0 15px;
  margin: 0 10px 0 0;
}

.primary-text-org {
  color: #F55937;
  font-weight: bolder;
  font-size: 50px;
  padding: 0 0 10px 0;
  margin: 0;
}

.primary-white-btn {
  padding: 20px 50px;
  margin: 0 10px 0 0;
  display: unset;
  border-radius: 3px;
  text-decoration: none;
  text-align: center;
  background-color: white;
  color: black;
  font-size: 24px;
  text-transform: uppercase;
}

.primary-white-btn:hover {
  background: #F55937;
  border: 2px solid #F55937;
  color: #fff;
}

.ani-img-01 {
  height: 140px;
  background-image: url('./assets/portfolio/slider-home-img-1.webp');
  background-position: center center;
  height: 140px;
  background-size: cover;
}

.ani-img-02 {
  height: 140px;
  background-image: url('./assets/portfolio/slider-home-img-2.webp');
  background-position: center center;
  height: 100px;
  background-size: cover;
}


.ani-img-03 {
  height: 140px;
  background-image: url('./assets/portfolio/slider-home-img-2.jpg');
  background-position-x: 0px;
  background-position-y: 319px;
  height: 127px;
  background-size: cover;
}

.portfolio h4 {
  text-transform: uppercase;
  font-weight: 500;
  padding: 0 0 10px 0;
  margin: 0 0 0 0;
  font-size: 35px;
  color: #fff;
}

/* Animation */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.image-slider {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
}

.image-slider img {
  display: inline-block;
  flex-shrink: 0;
  width: 100%;
}

.image-slider .slider-container button{
  width: 140px!important;
}

.slider-container {
  display: flex;
  animation: scroll 100s linear infinite;
}

.text-small {
  display: inline-block;
  font-size: 30px;
  padding: 10px 0 0 0;
  font-weight: 400;
}

/* COOKIES */
#overlay-1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1300;
}

#cookie-consent {
  padding: 1%;
  position: fixed;
  background-color: #2F292B;
  z-index: 10;
  width: 28%;
  top: 12%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  transition: opacity 0.5s ease-in-out;
  left: 35%;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
}


#cookie-settings {
  position: fixed;
  background-color: #2F292B;
  z-index: 10;
  width: 50%;
  top: 12%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  transition: opacity 0.5s ease-in-out;
  left: 25%;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
}

#cookie-settings .toggle-container p {
  font-weight: 400 !important;
}

#cookie-settings .orange-no-btn {
  background: #F55937;
  padding: 2% 4%;
  text-align: center;
}

#cookie-settings .orange-no-btn p {
  margin: 0;
  font-weight: 400;
}

#cookie-settings .grey-no-btn {
  background: #808080;
  padding: 2% 4%;
  text-align: center;
}

#cookie-settings .grey-no-btn p {
  margin: 0;
}

#cookie-settings .cookie-box {
  padding: 1%;
  height: 85vh;
}

#cookie-settings .cookie-box .img-logo {
  display: flex !important;
  justify-content: flex-end;
}

#cookie-settings .cookie-box .pos-rel {
  position: relative;
  bottom: 40px;
  padding: 20px 6% 5% 6%;
}

#cookie-consent .cookie-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#cookie-consent p {
  margin: 0;
  margin-bottom: 1%;
  font-weight: 400;
  text-align: center;
}

#cookie-consent .op-color {
  border-radius: 0.5rem;
  padding: 1.5% 1%;
  margin: 2% 4%;
}

#cookie-settings .op-color {
  border-radius: 0.5rem;
  padding: 1.1% 1%;
  margin: 1% 0%;
}

#cookie-consent .op-decline,
#cookie-settings .op-decline {
  background-color: #808080;
  border: 2px solid #808080;
}

#cookie-consent .btn-contains,
#cookie-settings .btn-contains {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.fade-out {
  opacity: 0;
  display: none !important;
}

.toggle-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 10px 0;
  color: white;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
  margin-left: 20%;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:checked+.slider:before {
  transform: translateX(26px);
}

.primary-btt {
  background: #F55937;
  border: 2px solid #F55937;
  color: #fff;
  text-decoration: none;
  padding: 15px 40px;
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
}

.primary-btt:hover {
  background: #E9401B;
}

.img-banner-w{
  width: 90%;
  height: 80vh;
}

@media (max-width: 300px) {}

@media (min-width: 301px) and (max-width: 637px) {

  #cookie-settings,
  #cookie-consent {
    width: 90% !important;
    left: 6%;
    padding: 4% 4%;
  }

  #cookie-consent {
    top: 16%;
  }

  #cookie-settings {
    top: 7%;
  }

  .toggle-switch {
    margin-left: 2%;
  }

  #cookie-settings .cookie-box {
    padding: 1%;
    height: 85vh;
  }

  button {
    font-size: 10px !important;
  }

  .ContainerHeader {
    padding-left: 0 !important;
  }

  .EmailIcon {
    width: 0.7rem !important;
  }

  .ContactBox {
    top: 0 !important;
  }

  .MuiToolbar-root {
    min-height: 0 !important
  }

  .bigTxt {
    font-size: 20px;
    line-height: 30px;
  }

  .primary-white-btn {
    padding: 10px 20px;
  }

  .text-small {
    font-size: 18px;
    padding: 2px 0 0 0;

  }

  .primary-text-org {
    font-size: 20px;
    padding: 0 0 4px 0;
  }

  .image-slider .slider-container button{
    width: 100px!important;
  }
}